@import "core/index.scss";
.productbox.type-regular {
	@include media {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.productbox-wrap {
		padding: 2rem 1.4rem;
		position: relative;
		width: 100%;

		@include media {
			padding: 1.5rem 1rem;
		}
	}

	@include mediaMin {
		.productbox-textarea {
			min-height: 13.5rem;
		}
	}

	.productbox-labels {
		&.top {
			position: absolute;
			top: 2.5rem;
			left: 2.4rem;
			
			@include media {
				top: 1rem;
				left: 1.5rem;
			}
		}

		&.bottom {
			margin-top: 1rem;
		}
	}

	.productbox-imagewrap {
		padding-bottom: 100%;
	}

	.productbox-title {
		margin-top: 2rem;
		height: 4rem;
	}

	.productbox-price {
		margin-top: 2rem;
	}

	.productbox-controls {
		padding: 1.5rem 1.5rem 1.5rem;

		@include media {
			padding: 1rem 1rem 1rem;
		}

		.controls-message {
			margin-bottom: .5rem;
			font-size: 1.3rem;
			color: $color-text-light;
		}

		@include media {
			&:not(.visible) {
				padding: 0 1rem;
				transform: none;
				position: relative;
				opacity: 1;
				display: block;
			}
		}
	}
}