@import "core/index.scss";
@mixin labelColors {
	&.pink {
		background-color: $color-pink;
		color: $color-white;
	}

	&.pink-bg {
		background-color: $color-pink-bg;
		color: $color-pink;
	}

	&.orange-bg,
	&.warning {
		background-color: $color-orange-bg;
		color: $color-orange;
	}

	&.orange {
		background-color: $color-orange;
		color: $color-white;
	}

	&.orange-light-bg {
		background-color: $color-orange-light-bg;
		color: $color-orange-light;
	}

	&.blue-bg {
		background-color: $color-blue-bg;
		color: $color-blue;
	}

	&.purple {
		background-color: $color-purple-bg;
		color: $color-purple;
	}

	&.error {
		background-color: $color-error-bg;
		color: $color-error;
	}

	&.success {
		background-color: $color-success-bg;
		color: $color-success;
	}
}

.label {
	@include font-bold;
	padding: .5rem .8rem .4rem;
	font-size: 1.3rem;
	line-height: 1;
	text-shadow: none;
	vertical-align: middle;
	background-color: $color-bg;
	color: $color-text-light;
	border-radius: $radius-small;
	// text-transform: uppercase;
	display: inline-block;

	+ .label {
		margin-left: .5em;
	}

	&.big {
		font-size: 1.6rem;
		padding: .7rem 1.5rem .7rem;
	}

	&.uppercase {
		text-transform: uppercase;
	}

	&.small {
		font-size: 1rem;
		padding: .3rem .6rem .2rem;
	}

	@include labelColors;
}